<style lang="scss">
.exklusivplatzierung {
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
    }
  }
  .image-box {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.outer-slider {
  .inner-box {
    padding-top: 0px !important;
    .kat {
      display: none !important;
    }
  }
}
</style>

<template>
  <div class="exklusivplatzierung content container">
    <h1>Exklusivplatzierung</h1>
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <p class="intro-txt">Exklusiv nehmen wir wörtlich. Nicht nur, dass Ihre Werbebotschaft auf prominenten Seiten (Titel, Leute) und beliebten Serviceteilen (Wetter, Rätsel, TV-Programm) der Zeitung platziert wird, wir sichern Ihnen auch Alleinstellung zu.</p>
      </div>
    </div>
  </div>
  <div class="bg-blue-light margin-b-m">
    <div class="wrapper-xxl outer-slider">
      <!-- -->
      <div class="print-overview-slider">
        <!-- <Slider :delay="4000" :items="{ 768: 2, 1024: 3, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay noclone>-->
        <Slider :delay="4000" :items="{ 768: 2, 1024: 2, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay>
          <template #navigation="{ left, right }">
            <div class="slider-navi-outer">
              <div @click="right"><span class="material-icons arrow-btn arrow-left">arrow_back_ios</span></div>
              <div @click="left"><span class="material-icons arrow-btn arrow-right">arrow_forward_ios</span></div>
            </div>
          </template>

          <template #items>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/1_Titel_Exklusiv.jpg" alt="Exlusiv-Platzierung" class="img-fluid" />
                </div>
                <div class="kat">Exlusiv-Platzierung</div>
                <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/10a_Wirtschaft_Boerse_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Exklusivplatzierung</div>
                <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>

            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/13_Mein-Tag-1-von-2_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Exklusivplatzierung</div>
                <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/13_Mein-Tag-2-von-2_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Exklusivplatzierung</div>
                <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/13a_Mein-Tag-Einzelseite_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Exklusivplatzierung</div>
                <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>

            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/22_TV-1-von-2_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Exklusivplatzierung</div>
                <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/22_TV-2-von-2_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Exklusivplatzierung</div>
                <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/23_Leute_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                </div>
                <div class="kat">Exklusivplatzierung</div>
                <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
          </template>
        </Slider>
      </div>
      <!-- -->
    </div>
  </div>

  <div class="exklusivplatzierung content container">
    <div class="text-center">
      <CollapsibleButton link="exklusivplatzierungen-tabellen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Ihre Werbemöglichkeiten</CollapsibleButton>
    </div>
    <Collapsible link="exklusivplatzierungen-tabellen">
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Titelseite</strong>
              Fixformat: 47 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">4.722,75</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3.386,25</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.072,25</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">4.997,25</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3.534,75</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.162,25</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x45.svg" alt="47x45" class="img-fluid " />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6"></div>
        <div class="col-lg-6"></div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Titelseite</strong>
              Fixformat: 98 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">9.445,50</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">6.772,50</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.144,50</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">9.994,50</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">7.069,50</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.324,50</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="98x45" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Titelseite + Leute / U4 </strong>
              Fixformat: 414 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">31.170,15</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">22.349,25</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">13.676,85</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">32.981,85</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">23.329,35</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">14.270,85</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-414x45.svg" alt="414x45" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Leute / U4</strong>
              Fixformat: 98 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">3.778,20</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.709,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.657,80</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">3.997,80</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">2.827,80</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.729,80</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="98x45" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Leute / U4</strong>
              Fixformat: 149 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.667,30</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.063,50</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.486,70</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.996,70</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">4.241,70</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.594,70</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x45.svg" alt="149x45" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Leute / U4</strong>
              Fixformat: 200 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.556,40</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.418,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.315,60</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">7.995,60</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">5.655,60</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">3.459,60</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x45.svg" alt="200x45" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>U3</strong>
              Fixformat: 200 x 275 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">28.861,25</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">20.693,75</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">12.663,75</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">30.538,75</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">21.601,25</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">13.213,75</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Mein Tag: Wetter</strong>
              Fixformat: 98 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.361,38</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">1.693,13</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.036,13</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">2.498,63</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">1.767,38</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.081,13</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="98x45" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Mein Tag: Wetter</strong>
              Fixformat: 200 x 45 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">4.722,75</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3.386,25</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.072,25</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">4.997,25</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3.534,75</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.162,25</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x45.svg" alt="200x45" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>TV-Programm</strong>
              Fixformat: 149 x 55 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">4.502,36</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3.228,23</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">1.975,55</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">4.764,05</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3.369,80</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.061,35</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x55.svg" alt="149x55" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Mein Tag: Rätselseite / Liebe ist… </strong>
              Fixformat: 75 x 72 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">3.929,37</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">-</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">-</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">4.157,38</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">-</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">-</div>
            </div>
            <div class="info-txt-tabelle">Nur Kombi buchbar, keine Erscheinung Sonn- und Feiertag.</div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-75x72.svg" alt="75x72" class="img-fluid " />
        </div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Börse </strong>
              Fixformat: 73 x 15 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">904,02</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">-</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">-</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">904,02</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">-</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">-</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-73x15.svg" alt="73x15" class="img-fluid " />
        </div>
      </div>
      <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer. Titel-, Aufschlagseite, U4 inklusive Aufpreis.</div>
    </Collapsible>
    <!--<div class="download-pdf">
      <div class="line"><span>Download</span></div>
      <a href="../../pdf/hauptblatt/Exklusiv-Platzierung-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif Exklusivplatzierung
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>445 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';
import Slider from '@/components/util/Slider.vue';
import SliderItem from '@/components/util/SliderItem.vue';

export default defineComponent({
  components: {
    CollapsibleButton,
    Collapsible,
    Slider,
    SliderItem,
  },
});
</script>
